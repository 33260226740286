import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";

// import { BsCaretDown, BsCaretUp } from "react-icons/bs";

function FAQCard({ question, answer }) {
  const [show, setShow] = useState(false);
  return (
    <div className=" flex flex-col mt-4">
      <h3 className="lg:text-xl text-base font-semibold  text-[#000] opacity-60 tracking-[2.25px]">
        <button
          className="p-4 text-start border-b-2 w-full flex justify-between gap-2 items-center"
          onClick={() => setShow((prevState) => !prevState)}>
          <span>{question}</span>

          {show ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
      </h3>
      {show && (
        <div className="p-4  transition-all duration-300 ease-in-out ">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
}

export default FAQCard;
