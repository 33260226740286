const homeFaqData = [
  {
    "question": "What's the estimated time required to create an invitation?",
    "answer": "Creating your invitation on our platform typically takes just about 20 minutes."
  },
  {
    "question": "Is it possible for me to design it myself?",
    "answer": "Absolutely! Our invitations are designed in a DIY (do it yourself) format, so you have full creative control."
  },
  {
    "question": "Can I make design changes even after sending out the invitations?",
    "answer": "Yes, you can freely update the design or any information on the invitation, even after sending it."
  },
  {
    "question": "Can I request modifications for my invitation?",
    "answer": "Certainly, we're happy to make custom modifications to your invitation as per your requirements, and we'll deliver it to you within 24 hours."
  },
  {
    "question": "I have a specific design in mind. Can you create the invitation in that design?",
    "answer": "Absolutely, we'd love to craft a unique design for you to ensure your invitation stands out from the rest."
  }
];

export {homeFaqData};
