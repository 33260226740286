import { useState } from "react";
import { Heading } from "./common/heading";
import { cn } from "@/lib/utils";
import FAQCard from "../common/FAQCard";
function FAQSection({ data }) {
  return (
    <div>
      <section className="px-4 py-1 hidden sm:block">
        <Heading
          title="Frequently Asked Questions"
          classes={"mb-6"}
        />
        <div className="lg:col-span-6 col-span-12 -mt-4">
          <div>
            {data.map((faq, index) => {
              return (
                <FAQCard
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              );
            })}
          </div>
        </div>
      </section>
      
      <section className="px-4 py-1 sm:hidden">
        <Heading
          title="Frequently Asked Questions"
          classes={"mb-6 text-center"}
        />
        <div className="lg:col-span-6 col-span-12 -mt-4">
          <div>
            {data.map((faq, index) => {
              return (
                <FAQCardMobile
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  lastItem={index === data.length - 1}
                />
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default FAQSection;

function FAQCardMobile({ question, answer, lastItem }) {
  const [show, setShow] = useState(false);
  return (
    <div className="flex flex-col mt-0 font-Jost">
      <h3 className="lg:text-xl text-base font-medium text-[#000] tracking-[2.25px]">
        <button
          className={cn(
            `p-4 text-start w-full flex justify-between gap-2 items-center`,
            show || lastItem ? "border-b-none" : "border-b"
          )}
          onClick={() => setShow((prevState) => !prevState)}
        >
          <span>{question}</span>
          {show ? (
            <img src="/assets/home/collapse.svg" alt="close" />
          ) : (
            <img src="/assets/home/expand.svg" alt="open" />
          )}
        </button>
      </h3>
      <div
        className={` bg-[#F3445633] rounded-lg  transition-all duration-300 ease-in-out font-[400] text-sm ${
          show ? "p-4 max-h-screen opacity-100 m-2" : "max-h-0 opacity-0"
        }`}
      >
        <p>{answer}</p>
      </div>
    </div>
  );
}
