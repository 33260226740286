import { cn } from "@/lib/utils";
import Link from "next/link";
import React from "react";

export const Heading = ({ title, classes }) => {
  return (
    <h3
      className={cn(
        `text-start lg:text-2xl text-[22px] font-bold lg:font-medium font-Mermaid text-lightRed lg:mb-4 mb-1 lg:my-4`,
        classes
      )}
    >
      {title}
    </h3>
  );
};

export const HyperLink = ({ link, redTheme }) => {
  return (
    <Link href={link}>
      <h3
        className={cn('underline lg:text-lg text-md font-light lg:font-medium font-Poppins',
          redTheme
            ? "text-white hover:text-white/80"
            : "text-[#00000099] hover:text-red-400"
        )}
      >
        View All
      </h3>
    </Link>
  );
};



/* Website Invites */

// position: absolute;
// width: 142px;
// height: 22px;
// left: 0.5px;
// top: 0px;

// font-family: 'Mermaid';
// font-style: normal;
// font-weight: 700;
// font-size: 22px;
// line-height: 22px;
// /* identical to box height */

// /* 01 */
// color: #F34456;

